import React from 'react';
import Hero from '../components/Hero';

import './HomePage.css';

const HomePage = () => {
  return (
    <div className="home">
      <Hero />
    </div>
  );
}

export default HomePage;
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import img from "../../assets/images/app-dev.png"

import "./AppDevMain.css"

const ApplicationDevelopmentMaintainance = () => {
  return (
    <Container className="application-dev-maintainence-container">
      <Row>
        <Col>
          <img className="header-img" src={img} alt="app-dev"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Application Development & Maintenance</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Majority of the companies require applications in a wide range of usabilities. An application is expected to simplify the operating process, less time and effort indulgence being the prime motive. This calls for an application development team that is technologically sound along with showcasing quick thinking and problem solving abilities. We have an experienced recruitment team dedicated towards bringing a team on board that can facilitate the development of applications focusing on ease and an optimum usage.
          </p>
          <p>
            Prospek Technologies Application Development and Maintenance team also provides industrialized and flexible models to help companies design, build and continually improve the applications that run their business. Most organizations contain a vast portfolio of applications like custom, package, mobile, and legacy, that is developed and maintained across varying application platforms on premise, cloud-enabled, and cloud native.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Technologies We Support:</h5>
          <ul>
            <li>Business Intelligence: Oracle Hyperion Suite, Obiee, Sap Bo, Qlikview, Tableau</li>
            <li>Etl And Datawarehousing: Informatica, Ibm Infosphere Datastage, Ssis</li>
            <li>Big Data Tools: Hadoop, Pig, Hive, Spark, Cloudera, Hortonworks</li>
            <li>Cloud Hosting: Amazon Web Services (Amazon Web Service, Microsoft Azure, Google Cloud Platform, Red Hat)</li>
            <li>Database Design, Development And Administration: Oracle, Ms Sql Server</li>
            <li>Nosql Databases: Mongodb, Cassandra</li>
            <li>Infrastructure/Devops (Unix, Windows)</li>
            <li>Web UI Development: ReactJS, BackBone JS</li>
            <li>API Development: Java, C++, Ruby, Clojure, Python, Specialize In Both SQL &amp; No-SQL Databases</li>
            <li>Mobile Development: React Native, Flutter, Swift</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ApplicationDevelopmentMaintainance;

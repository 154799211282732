import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

import "./CareersPage.css";

const CareersPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [gender, setGender] = useState('');
  const [position, setPosition] = useState('');
  const [dob, setDob] = useState('');
  const [qualification, setQualification] = useState('');
  const [portfolio, setPortfolio] = useState('');
  const [resume, setResume] = useState(null);
  const [lastCompany, setLastCompany] = useState('');
  const [experience, setExperience] = useState('');
  const [comments, setComments] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(resume);
  };

  return (
    <Container className="careers-container">
      <Row>
        <Col>
          <div className="form-header">
            <div className="content">
              <h2 className="form-heading">--APPLY HERE--</h2>
              <h6 className="form-content">We nurture and cultivate a sense pride in the work and create team leaders. Your growth chart will be governed by well-defined transparent policies of the firm. Apply via the form given below and meet us for face to face interview.</h6>
            </div>
          </div>
          <Form className="careers-form" onSubmit={handleSubmit}>
            <Form.Group className="form-group">
              <Form.Label className="form-label">First Name *</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Last Name *</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Email *</Form.Label>
              <Form.Control
                className="form-control"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Mobile No *</Form.Label>
              <Form.Control
                className="form-control"
                type="tel"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Gender *</Form.Label>
              <Form.Control
                className="form-control"
                as="select"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                required
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="female">Don't want to mention</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Position or Role You Are Applying For</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">DOB *</Form.Label>
              <Form.Control
                className="form-control"
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Highest Qualification</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Portfolio Link</Form.Label>
              <Form.Control
                className="form-control"
                type="url"
                value={portfolio}
                onChange={(e) => setPortfolio(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Upload Resume *</Form.Label>
              <Form.Control
                className="form-control"
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={(e) => setResume(e.target.files[0])}
                required
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Last Company Worked For</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                value={lastCompany}
                onChange={(e) => setLastCompany(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Experience (in years and months)</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="form-label">Reference/Comments/Questions</Form.Label>
              <Form.Control className="form-control"
                as="textarea"
                rows={4}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </Form.Group>

            <Button type="submit">
              Send Application
            </Button>
          </Form>
        </Col>
      </Row>

    </Container>
  );
};
export default CareersPage;

import React from "react";
import { Accordion } from "react-bootstrap";

import "./CollapsibleCard.css";

const CollapsibleCard = ({ items }) => {
    return (
        <Accordion className="vmc-accordion">
            {items.map((item, index) => (
                <Accordion.Item className="vmc-accordion-item" eventKey={index.toString()}>
                    <Accordion.Header className="vmc-accordion-header"><span className="vmc-icon">{item.icon}</span>{item.title}</Accordion.Header>
                    <Accordion.Body className="vmc-accordion-body">{item.content}</Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default CollapsibleCard;

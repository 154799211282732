import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import img from "../../assets/images/qa-testing.jpg"


import "./QATesting.css"

const QATesting = () => {
  return (
    <Container className="qa-testing-container">
      <Row>
        <Col>
          <img className="header-img" src={img} alt="qa-testing-dev"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>QA & Testing</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Enterprises have a responsibility of developing technologies that are efficient and innovative to meet the market's demands. At Prospek Technologies, our professionals possess a thorough understanding of the same that enables them to create a smooth and an unwavering testing process, ensuring perfect outcome at the user's end. Our qualified testing experts pass products that promise an uninterrupted performance to help the enterprise uplift it's brand name in terms of quality.</p>
          <p>We provide testing services catering to a diverse range of requirements. Supporting a brilliant gradation of the process, we inculcate a sequence of assurance parameters in developing the technology Our approach towards attaining an efficient testing process is ruled by a systematic series of activities. Development of strategy for a specific product as per it's requirements followed by effectually consulting the efficiency of its automation process helps us to harness the best out of our testing endeavors.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Our Key Differentiators</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>Mobilisation Of Testing Professionals With An Array Of Expertise: More Than 300 Testers Working For Prospek Technologies At Various Client Locations.</li>
            <li>A Team Dedicated To The Recruitment Of Quality Analysts/Testers.</li>
            <li>Test Automation Consulting</li>
            <li>End-To-End Testing Of An Application: System Testing, Integration Testing And Regression Testing.</li>
            <li>Specialized In Etl/Datawarehouse Testing And Test Automation.</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Our Capabilities Include</h5>
        </Col>
      </Row>
      <Row>
        <Col>
        <h5>Test Consulting and Advisory Services:</h5>
          <ul>
            <li>Test Strategy Definition</li>
            <li>Test Process Consulting</li>
            <li>Test Automation Consulting</li>
            <li>Performance Test Consulting</li>
            <li>Tool/ Product Evaluation, Administration And Management</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
        <h5>Test Services Implementation</h5>
          <ul>
            <li>Functional Testing</li>
            <li>Performance, Security, Localization, Usability And Accessibility Testing</li>
            <li>Test Automation</li>
            <li>Specialized Testing: ERP, SOA, DWH, CRM, Migration</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
        <h5>Managed Services</h5>
          <ul>
            <li>Test Environment Management</li>
            <li>Test Data Management</li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default QATesting;
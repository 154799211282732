import React from 'react';
import ApplicationDevelopmentMaintainance from "../services/it_consulting/AppDevMain";
import BigdataSolutions from "../services/it_consulting/BigdataSolutions";
import BlockchainServices from "../services/it_consulting/BlockchainServices";
import Devops from "../services/it_consulting/Devops";
import QATesting from "../services/it_consulting/QATesting";
import ITStaffAugmentation from "../services/it_staffing/ITStaffAugmentation";
import MasterVendorProgram from "../services/it_staffing/MasterVendorProgram";
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import ServicesPage from '../pages/ServicesPage';
import CareersPage from '../pages/CareersPage';
import ContactPage from '../pages/ContactPage';
import { FaRegLightbulb, FaRegGem } from 'react-icons/fa';
import { FiTarget } from "react-icons/fi";

const mainIDs = {
    IT_STAFFING: "/services/it-staffing",
    IT_CONSULTING: "/services/it-consulting",
};
const serviceIDs = {
    IT_STAFF_AUGMENTATION: `${mainIDs.IT_STAFFING}/it-staff-augmentation`,
    MASTER_VENDOR_PROGRAM: `${mainIDs.IT_STAFFING}/master-vendor-program`,
    APPLICATION_DEVELOPMENT_AND_MAINTAINANCE: `${mainIDs.IT_CONSULTING}/application-development-and-maintainance`,
    BLOCKCHAIN_SERVICES: `${mainIDs.IT_CONSULTING}/blockchain-services`,
    QA_AND_TESTING: `${mainIDs.IT_CONSULTING}/qa-and-testing`,
    DEVOPS: `${mainIDs.IT_CONSULTING}/devops`,
    BIGDATA_SOLUTIONS: `${mainIDs.IT_CONSULTING}/big-data-solutions`
};
const serviceTitles = {
    IT_STAFFING: "IT Staffing",
    IT_CONSULTING: "IT Consulting",
    IT_STAFF_AUGMENTATION: "IT Staff Augumentation",
    MASTER_VENDOR_PROGRAM: "Master Vendor Program",
    APPLICATION_DEVELOPMENT_AND_MAINTAINANCE: "Application Development & Maintainance",
    BLOCKCHAIN_SERVICES: "Blockchain Services",
    QA_AND_TESTING: "QA & Testing",
    DEVOPS: "Devops",
    BIGDATA_SOLUTIONS: "Bigdata Solutions",
};
const serviceDescriptions = {
    APPLICATION_DEVELOPMENT_AND_MAINTAINANCE: "Majority of the companies require applications in a wide range of usabilities. An application is expected to simplify",
    BLOCKCHAIN_SERVICES: "A blockchain, originally blockchain, is a growing list of records, called blocks, which are linked using cryptography.",
    QA_AND_TESTING: "Enterprises have a responsibility of developing technologies that are efficient and innovative to meet the markets",
    DEVOPS: "More companies are switching to Agile and will face this transition - from Traditional to Continues Integration environment",
    BIGDATA_SOLUTIONS: "Majority of leading firms are investing in analytics to develop new products and to enhance their existing sales",
    IT_STAFF_AUGMENTATION: "Prospek Technologies focuses on delivering quality work with maintaining a cordial relationship with its clients. Our innovative Staffing and Consulting solutions make us reliable partners for all your IT and pharma needs.",
    MASTER_VENDOR_PROGRAM: "Having trouble managing multiple vendors and looking for a consolidated single Master Vendor Program - Prospek Technologies is best suitable for all your needs."
}
export const services = [
    {
        id: mainIDs.IT_STAFFING,
        title: serviceTitles.IT_STAFFING,
        children: [
            {
                id: serviceIDs.IT_STAFF_AUGMENTATION,
                title: serviceTitles.IT_STAFF_AUGMENTATION,
                description: serviceDescriptions.IT_STAFF_AUGMENTATION,
                component: <ITStaffAugmentation />,
            },
            {
                id: serviceIDs.MASTER_VENDOR_PROGRAM,
                title: serviceTitles.MASTER_VENDOR_PROGRAM,
                description: serviceDescriptions.MASTER_VENDOR_PROGRAM,
                component: <MasterVendorProgram />,
            }
        ],
    },
    {
        id: mainIDs.IT_CONSULTING,
        title: serviceTitles.IT_CONSULTING,
        children: [
            {
                id: serviceIDs.APPLICATION_DEVELOPMENT_AND_MAINTAINANCE,
                title: serviceTitles.APPLICATION_DEVELOPMENT_AND_MAINTAINANCE,
                description: serviceDescriptions.APPLICATION_DEVELOPMENT_AND_MAINTAINANCE,
                component: <ApplicationDevelopmentMaintainance />,
            },
            {
                id: serviceIDs.BLOCKCHAIN_SERVICES,
                title: serviceTitles.BLOCKCHAIN_SERVICES,
                description: serviceDescriptions.BLOCKCHAIN_SERVICES,
                component: <BlockchainServices />,
            },
            {
                id: serviceIDs.QA_AND_TESTING,
                title: serviceTitles.QA_AND_TESTING,
                description: serviceDescriptions.QA_AND_TESTING,
                component: <QATesting />,
            },
            {
                id: serviceIDs.DEVOPS,
                title: serviceTitles.DEVOPS,
                description: serviceDescriptions.DEVOPS,
                component: <Devops />,
            },
            {
                id: serviceIDs.BIGDATA_SOLUTIONS,
                title: serviceTitles.BIGDATA_SOLUTIONS,
                description: serviceDescriptions.BIGDATA_SOLUTIONS,
                component: <BigdataSolutions />,
            },
        ],
    },
];
export const navMenu = [
    {
        id: "/",
        title: "Home",
        component: <HomePage />,
    },
    {
        id: "/about",
        title: "About",
        component: <AboutPage />,
    },
    {
        id: "/services",
        title: "Services",
        component: <ServicesPage />,
        children: services,
    },
    {
        id: "/careers",
        title: "Careers",
        component: <CareersPage />,
    },
    {
        id: "/contact",
        title: "Contact Us",
        component: <ContactPage />,
    },
];

export const vmc = [{
    icon: <FaRegLightbulb />,
    title: "VISION",
    content: "We have a vision in being the solution-partner for our clients that can appropriately comprehend the requirements of the project and redistribute the suitable methods for its timely execution. Our relationship with the enterprises never ceases in nature. We work with them in attaining the best customer-relationship along with facilitating an enhancement of the brand value."
}, {
    icon: <FiTarget />,
    title: "MISSION",
    content: "We work in giving optimum quality to the clients by shrinking the gap between exemplary technology and efficient implementing methods. Our talented staffing professionals work with every client as a true partner."
}, {
    icon:<FaRegGem/>,
    title: "CORE VALUES",
    content: "Core values form the integral part of Prospek Technologies daily endeavors and reflects well in the delivering of service to our clients. We constantly reinforce and continually align our organization around these fundamental principles."
}];
export const outroDescriptions = [
    {
        title: "Proven Management Strategies",
        content: "Prospek Technologies’s client-centric strategy combines deep business insight as well as technology impact on various industries and business models. Prospek Technologies’s Management Strategy understands the mechanics of organizational success and have the people, skills, insights and deep industry experience needed to shape new forms of client value.",
    },
    {
        title: "Excellent Skills & Proficiency",
        content: "As the technology landscape is turning highly complex to meet the demands of competitive marketplace, Our passionate employees and consultants go beyond traditional methods to develop bold innovations and new partnerships. They own your business challenges, reimagine business solutions to help create new markets.",
    },
    {
        content: "Build beneficial partnership with us and gain access to the best IT talent across India. Prospek Technologies has strategically aligned itself to develop some of the most innovative solutions for its clients. From providing turnkey project-based solutions to on-demand staff augmentation to application development services, Prospek Technologies can be your long-term strategic partner."
    }
];
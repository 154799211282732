import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { navMenu } from '../constants';
import { Link } from 'react-router-dom';
import logo from "../assets/logos/Prospek-2.png"
import "./Header.css";

const Navigation = () => {
  return (
    <Navbar collapseOnSelect bg="transparent" expand="lg" variant="dark" className="navbar-container">
      <Container>
        <Navbar.Brand href="/"><img src={logo} alt="Prospek Technologies"></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            {navMenu.map((nav) => {
              if(!nav.children){
                return (
                  <Nav.Link as={Link} to={`${nav.id}`}>{nav.title}</Nav.Link>
                );
              } else{
                return(
                  <NavDropdown menuVariant="dark" key={nav.id} title={nav.title} id="collasible-nav-dropdown">
                    {nav.children.map((subnav) => {
                      if(!subnav.children){
                        return(
                          <NavDropdown.Item as={Link} to={`${subnav.id}`}>
                            {subnav.title}
                          </NavDropdown.Item>
                        );
                      }
                      else{
                        return(
                          <NavDropdown menuVariant="dark" key={subnav.id} title={subnav.title} id="collasible-nav-dropdown">
                            {subnav.children.map((sub) => {
                              return(
                                <NavDropdown.Item as={Link} to={`${sub.id}`}>
                                  {sub.title}
                                </NavDropdown.Item>
                              );
                            })}
                          </NavDropdown>
                          );
                      }
                    })}
                  </NavDropdown>
                );
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
const Header = () => {
  return (
    <div className="header">
      <Navigation />
    </div>
    
  );
};

export default Header;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import img from "../../assets/images/master-vendor-program.jpg";

import "./MasterVendorProgram.css"

const MasterVendorProgram = () => {
  return (
    <Container className="master-vendor-program-container">
      <Row>
        <Col>
          <img className="header-img" src={img} alt="master-vendor-program"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Master Vendor Program</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Having trouble managing multiple vendors and looking for a consolidated single Master Vendor Program - Prospek Technologies is best suitable for all your needs. Locally established dedicated team of experts gives personalized solution and makes sure that everything flows smoothly right from onboarding of resources to resource transitions to offboarding. We maintain our recruitment quality practices throughout the Master Vendor Program aiming to provide credible staffing solutions to our clients; eradicating the possibility of deficient procurement.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>We Consolidate All Your Suppliers Onto One Platform To Help You Manage Better</li>
            <li>Single Point Of Contact For Managing All Your Vendors</li>
            <li>Clear Visibility To The Quality And Performance Of Each Individual Supplier Through Analytics And Reports</li>
            <li>SLA Driven Execution</li>
            <li>Advanced VMS - Robust Framework, Fully Standardized, To Summon And Manage Contractors</li>
            <li>We Stick To An Optimized List Of Preferred Suppliers</li>
            <li>Vacancies Are Filled Better - Management Gets Important And Useful Information On The Recruitment Process</li>
            <li>Simplified Billing, Payment And Administration</li>
            <li>We Bring About Much Better Experience For The Candidates And Suppliers</li>
            <li>We Act As A 'One Stop Shop' To Simplify The Recruitment Process</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default MasterVendorProgram;

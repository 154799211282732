import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './util/ScrollToTop';
import Header from './components/Header';
import NotFoundPage from './pages/NotFoundPage';
import { navMenu } from './constants';
import Footer from './components/Footer';
import ServicesPage from './pages/ServicesPage';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        {navMenu.map((item, index)=>{
          if (!item.children){
            return <Route exact path={item.id} element={item.component} key={item.id} />
          } else {
             return item.children.map((nav, idx) => {
              if(!nav.children){
                return <Route exact path={nav.id} element={nav.component} key={nav.id} />
              } else {
               return nav.children.map((subnav, inx) => (
                  <Route exact path={subnav.id} element={subnav.component} key={subnav.id} />
                ))
              }
            })
          }
        })}
        <Route exact path="/services" element={<ServicesPage />} />
        <Route exact path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

import img from "../../assets/images/devops.jpg"

import "./Devops.css";

const Devops = () => {
  return (
    <Container className="devops-container">
      <Row>
        <Col>
          <img className="header-img" src={img} alt="qa-testing-dev"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>DEVOPS</h3>
        </Col>
      </Row>
      <Row>
        <Col>
        <p>More companies are switching to Agile and will face this transition from Traditional to Continuous Integration environment (DevOps)</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>DevOps Consulting Services</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>DevOps - continues integration of software coding, testing and delivery capabilities for increased automation, efficiency and product quality.</p>
          <ul>
            <li>
              <h6>Maturity Assessment:</h6>Analyze How This Process Can Help Your Company To Be More Effective To Get To The Final Product By Using Continuous Integration And Continuous Delivery.
            </li>
            <li>
              <h6>Infrastructure Architecture:</h6>Designing Solutions Allowing To Combine In One Set Of Detailed Documentation And Incremental Software Delivers For All Development Engineering Teams To Work Collaboratively, Make The Feedback Cycle Much Shorter And Keep All Stakeholders In The Loop.
            </li>
            <li>
              <h6>Infrastructure Tools:</h6>Leveraging Our Experience Across Applied Across Multiple Clients, We'll Provide You With Recommendations For Devops Toolchain Which Will Allow You To Not Only Reduce Your Production Time, But Also To Develop A Better-Quality Product And Most Importantly, Finally Clear Your It Backlog And Implement A Wish List, That Has Been Piled Up For Years.
            </li>
            <li>
              <h6>DevOps Training:</h6>We Can Also Provide You With Onsite Training To Learn From The Experts About The Best Good Devops Practices.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Devops
import React from 'react';
import { useSpring, animated } from 'react-spring';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import about_1 from "../assets/images/about-img1.jpg"

import './AboutPage.css';

const AboutPage = () => {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.div style={fade}>
      <Container className="about-container">
        <Row>
          <Col>
          <img className="about-img"src={about_1} alt="about-1"></img>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Why Prospek Technologies?</h3>
            <hr className="line-break"/>
            <p>Prospek Technologies focuses on delivering quality work with maintaining a cordial relationship with its clients. Our innovative Staffing and Consulting solutions make us reliable partners for all your IT and pharma needs.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>What We Do?</h3>
            <hr className="line-break"/>

            <h6>Provide innovative staffing and consulting solutions.</h6>
            <p>We have resources to serve your business by complying to your unique requirements. Clients choose Prospek technologies as their workforce partner to solve staffing challenges that range from locating hard-to-find niche talent to completing quick-fill demands. Along with a range of staffing options like contract, contract-to-hire staffing services, Prospek technologies also pioneers in developing tailored solutions in current technology trends to adapt to the changing dimensions of the market.</p>
            <p>Providing IT professionals a rewarding career with great companies. We hire talented, passionate employees and provide a positive, rewarding work environment where they can thrive and excel. We placed hundreds of IT professionals with our various clients in various technology areas. We constantly monitor their performance and provide professional and technical training wherever necessary to excel in their jobs.</p>
          </Col>
        </Row>
      </Container>
    </animated.div>
  );
};

export default AboutPage;

import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import "./ContactPage.css"

const ContactPage = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <Container className="contact-container">
      <Row className="contact-row">
        <Col lg={12}>
          <h2>Contact Us</h2>
          <hr className="contact-line-break"/>
        </Col>
      </Row>
      <Row className="contact-row">
        <Col lg={12}>
          <h4>Office Address</h4>
          <p>Flat No: 402, Vijayanand Castle, Alwal Hills Rd Number 12, Rani Vihar, Alwal, Secunderabad, Telangana, 500010</p>
          <hr className="contact-line-break"/>
        </Col>
        <Col lg={12}>
          <h4>Contact Information</h4>
          <p>Phone: +916304493892</p>
          <p>Email: info@prospektechnologies.com</p>
          <hr className="contact-line-break"/>
        </Col>
      </Row>
      <Row className="contact-row">
        <Col>
          <h4>Location</h4>
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.819404757724!2d78.49200734043771!3d17.516132299150936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9ab4292066e7%3A0xf441c0a730d27a56!2sVijayanand%20Castle!5e0!3m2!1sen!2sin!4v1684249420762!5m2!1sen!2sin" width="100%" height="400" style={{ border: 0 }} allowfullscreen=""
          aria-hidden="false"
          title="Address"
          tabIndex="0"></iframe>
          <hr className="contact-line-break"/>
        </Col>
      </Row>
      <Row className="contact-row">
        <Col lg={12}>
          <h4>Send Us a Message</h4>
          <Form onSubmit={handleSubmit} className="contact-form">
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" required />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" required />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="tel" name="phone" required />
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;

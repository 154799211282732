import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';

import { vmc, services, outroDescriptions } from '../constants';
import CollapsibleCard from '../util/CollapsibleCard';
import ServiceCardGroup from '../util/ServiceCardGroup';

import hero_1 from "../assets/images/hero-img1.jpg";
import hero_2 from "../assets/images/hero-img2.jpg";
import { TfiAngleDoubleRight } from "react-icons/tfi";

import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col>
            <img className="header-img" src={hero_1} alt="bg-image1"></img>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Your Trusted Partner</h1>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="intro-section">
            <p>
              Prospek Technologies focuses on delivering quality work with maintaining a cordial relationship with its clients. Our innovative Staffing and Consulting solutions make us reliable partners for all your IT and pharma needs.
            </p>
            <Button as={Link} to="/about">
              Explore More <span className="icon-button" ><TfiAngleDoubleRight/></span>
            </Button>
          </Col>
          <Col>
            <img src={hero_2} alt="hero-2"></img>
          </Col>
        </Row>
        <Row className="vmc-section">
          <Col>
            <CollapsibleCard items={vmc} />
          </Col>
        </Row>
        <Row className="service-group-section">
          <Col>
            <h6>OUR Services</h6>
            <h4>What we do?</h4>
            <ServiceCardGroup serviceGroups={services} />
          </Col>
        </Row>
        <Row className="outro-section">
          <Col>
            <h6>
              Why did Business Choose Us?
            </h6>
            <h4>
              Jumpstart your business
            </h4>
            {outroDescriptions.map((item) => {
              if (item.title) {
                return (
                  <div>
                    <h5>{item.title}</h5>
                    <p className="outro-section">{item.content}</p>
                  </div>
                );
              } else {
                return (
                    <p className="outro-section">{item.content}</p>
                );
              }
            })}
          </Col>
        </Row>
        <Row className="contact-section">
          <Col className="text-center">
            <h2>Get in Touch</h2>
            <Button as={Link} to="/contact">
              Contact Us
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Hero;
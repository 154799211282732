import React from 'react';
import { Link } from 'react-router-dom';
import { navMenu } from '../constants';
import logo from "../assets/logos/Prospek-2.png"

import "./Footer.css"

const CopyrightInfo = () => {
  return (
    <>
    <img src={logo} alt="Prospek Technologies"></img>    
    <p className="footer-p">© 2023 Prospek Technologies. All Rights Reserved</p>
    </>
  );
};

const MainLinks = () => {
  return (
    <ul className="footer-ul">
      {navMenu.map((item) => {
        return <li><Link className="footer-links" to={item.id}>{item.title}</Link></li>
      })}
    </ul>
  );
};

const ContactInfo = () => {
  return (
    <div>
      <p className="footer-p">Flat No: 402, Vijayanand Castle, Alwal Hills Rd Number 12, Rani Vihar, Alwal, Secunderabad, Telangana, 500010</p>
      <p className="footer-p">Phone: +916304493892 </p>
      <p className="footer-p">Email: info@prospektechnologies.com</p>
    </div>
  );
};

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="row">
          <div className="col-lg-4 col-md-6 footer-col">
            <CopyrightInfo />
          </div>
          <div className="col-lg-4 col-md-6 footer-col">
            <h4>Useful Links</h4>
            <MainLinks />
          </div>
          <div className="col-lg-4 col-md-6 footer-col">
            <h4>Contacts</h4>
            <ContactInfo />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

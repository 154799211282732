import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import img from '../../assets/images/it-staff-augmentation.jpg'

import './ITStaffAugmentation.css';

const ITStaffAugmentation = () => {
  return (
    <Container className="it-staff-augmentation-container">
      <Row>
        <Col>
          <img className="header-img" src={img} alt="it-staff-augmentation"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>IT Staff Augmentation</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Continuously Add New Skills</h5>
          <p>Prospek Technologies focuses on delivering quality work with maintaining a cordial relationship with its clients. Our innovative Staffing and Consulting solutions make us reliable partners for all your IT and pharma needs.</p>
          <p>Embarking on to new markets or new platforms to remain competitive requires managing a stable IT team and environment without losing focus on core competencies. Maintaining reliable IT environment, sourcing right IT talent and maintaining them can be particularly challenging and absolutely imperative.</p>
          <p>Prospek Technologies Staff Augmentation services gives your organization access to the myriad of IT skills that match your business requirements. It is a simplified and quickest way to gain control over your technical staff while sizing up without the hassle of maintaining good resources in-house. Our skilled application professionals can develop and maintain all applications according to your workflow while meeting aggressive project timelines.</p>
        </Col>
      </Row>
    </Container>
  )
}

export default ITStaffAugmentation
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ServiceCard from './ServiceCard';

import "./ServiceCardGroup.css";

const ServiceCardGroup = ({ serviceGroups }) => {
  return (
    <Container className="service-group-container">
      {serviceGroups.map((group, index) => (
        <div key={index}>
          <h2>{group.title}</h2>
          <Row>
            {group.children.map((service, index) => (
              <Col lg={6} md={6} key={index}>
                <ServiceCard service={service} />
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default ServiceCardGroup;

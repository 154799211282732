import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import "./ServiceCard.css";

const ServiceCard = ({ service }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{service.title}</Card.Title>
        <Card.Text>{service.description}</Card.Text>
        <Button as={Link} to={service.id}>
          Learn more
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ServiceCard;
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import img from "../../assets/images/blockchain-dev.jpg"

import "./BlockChainServices.css"

const BlockchainServices = () => {
  return (
    <Container className="blockchain-services-container">
      <Row>
        <Col>
          <img className="header-img" src={img} alt="blockchain-dev"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>BLOCKCHAIN SERVICES</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>What is Blockchain?</h5>
          <p>
            A blockchain, originally blockchain, is a growing list of records, called blocks, which are linked using cryptography. Blockchains which are readable by the public are widely used by cryptocurrencies. Private blockchains have been proposed for business uses.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Blockchain Consultation</h5>
          <p>
            Blockchain is the new cutting-edge technology with the potential to be used across all industries. We can offer solutions to build blockchain-based products and web services, with long-term operations related help. Our highly trained team can provide excellent consultation support and develop frameworks that fit any domain needs.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Where to use Blockchain?</h5>
          <p>
            Blockchain is being acknowledged as a technology that can be used for multiple domains: banking, supply chain, energy, healthcare, and others. It helps to reduce costs, increase efficiency, improve performance and privacy, and open new business opportunities. We can evaluate your existing products and recommend the best uses of blockchain solutions for your organization.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Blockchain Implementation</h5>
          <p>
            Many companies are using blockchain for cryptocurrencies, but there is increased interest in this technology for existing enterprise solutions, such as Salesforce. Leveraging blockchain technology with these applications provides the ability to create immutable, secure ledgers that can prove transactions while keeping data confidential, which is a key requirement in many enterprises. We have expertise in infrastructure architecture and the development of blockchain-related products, including long-term assistance with technological and operational matters.
          </p>
          <p>As a Blockchain Solution provider, we will be involved in all development phases:</p>
          <ul>
            <li>Discovery and Assessment of Your Project</li>
            <li>Gathering and Analyzing All Requirements</li>
            <li>Identifying and Recommending Type of Blockchain Platform Based on Unique User Stories/Use Case</li>
            <li>Providing Resources/Expertise in Design and Development (Onsite and Offsite)</li>
            <li>Blockchain Application Testing and Deployment</li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default BlockchainServices;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import img from "../../assets/images/bigdata.jpg"
import img_2 from "../../assets/images/data-warehouse.jpg"

import "./BigdataSolutions.css";

const BigdataSolutions = () => {
  return (
    <Container className="bigdata-solutions-container">
      <Row>
        <Col>
          <img className="header-img" src={img} alt="bigdata-sols"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>BigData Solutions</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>Datawarehousing And Business Intelligence</li>
            <li>Blockchain</li>
            <li>Couchbase</li>
            <li>MongoDB</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>DATAWAREHOUSING AND BUSINESS INTELLIGENCE</h5>
          <hr className="line-break" />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Majority Of Leading Firms Are Investing In Analytics To Develop New Products And To Enhance Their Existing Sales - A Smart Way To Maximize Profits Capturing The Unruly 'Big Data' As Well As Structured Data From Both Internal And External Sources Is The First Step Towards The Analytics. Integrating The Data From These Various Sources Will Yield New Insights Through Predictive And Prescriptive Models.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <img className="header-img" src={img_2} alt="data-warehouse">
          </img>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Prospek Tech's Resources Bring In The Expertise That Your Organization Needs - Be It Building The Traditional Enterprise Data Warehouse For Capturing Information Or Implementing The Business Intelligence Software For Querying And Reporting The Data, Or Building A Big Data Solution Using Hadoop Framework Along With NoSQL Databases.</p>
          <p>A Development From Scratch Or Mid-Way Handling - Our Experts Take Effective Steps To Understand The Process And Work For An Optimum Output.</p>
          <p>More Organizations Are Modernizing Their BI And Analytics Platforms To More User-Driven, Visual And Interactive Dashboards With Data Coming From Various Sources.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>BLOCKCHAIN SERVICES</h5>
          <hr className="line-break" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Consultation</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Blockchain Technology is not only Bitcoin - it can be used for any product/industry where trustless transactions could be done between complete strangers. We explain the concept to you with examples and all related to your project details.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Where to use Blockchain?</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Blockchain is being acknowledged as a technology that can be used for multiple domains: banking, supply chain, energy, healthcare, and others. Blockchain technologies can help reduce costs, increase efficiency with better performance and privacy, and even open new business opportunities. Blockchain provides a new way for businesses to share data and automate processes across a company network. We can provide a complete evaluation of the existing product and recommend a new model for your organization on the best uses of blockchain solutions, including all implementation phases.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Blockchain Implementation</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Many companies are using blockchain for cryptocurrencies, but there is increased interest in this technology for existing enterprise solutions, such as Salesforce. Leveraging blockchain technology with these applications will provide the ability to create immutable, secure ledgers that can prove transactions, while keeping data confidential, which is a key requirement in many enterprises. We have expertise in infrastructure architecture and development of blockchain-related products, including long-term assistance with technological and operational matters.
          </p>
          <p>
            As a Blockchain Solution provider, we will be involved in all development phases:
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>Discovery and assessment of your project</li>
            <li>Gathering and analyzing all requirements</li>
            <li>Identifying and recommending the type of blockchain platform based on unique user stories/use cases</li>
            <li>Providing resources and expertise in design and development (onsite and offsite)</li>
            <li>Blockchain application testing and deployment</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>COUCHBASE SERVICES</h5>
          <hr className="line-break" />

        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            If you are still evaluating or already decided to move to NoSQL Couchbase solution, Prospek Technologies can help you with every step of this process, providing expertise, innovation, and leadership. We have experienced professionals to work on the architectural and development phases for your Couchbase project based on the Statement of Work (SOW) or by integrating with your team as an expert mentor, allowing you to pick the service you need and get the most value from our engagement.
          </p>
          <p>
            Using Couchbase, today's Digital World is powered by the Internet with modern technologies: the cloud, mobile devices, big data, and more. Businesses increasingly rely on applications that interact with their customers 24/7 from any location and support a large number of concurrent users, while remaining responsive and always available. NoSQL Couchbase can be used as the backend solution for these new age applications with unprecedented levels of scale, speed, and data variability.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Consultation</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            With the Couchbase document-oriented database, we help you build applications by leveraging the power of SQL and JSON. Create end-to-end mobile solutions with sync capabilities to the data center or cloud. Prospek Technologies provides consulting services to deliver focused Couchbase solutions. Our team of certified professionals will assist you on projects that require Couchbase expertise and experience through our consulting, systems integration, and implementation services.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Couchbase Solutions</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            We offer a wide range of consulting services and assist our clients throughout the full project lifecycle, including initial evaluation and POC. Our skills cover:
          </p>
          <ul>
            <li>Couchbase Server Up To The V.5.5.X Series</li>
            <li>Couchbase Mobile Development And Sync Management</li>
            <li>Xdcr/Data Encryption</li>
            <li>Memory Optimization</li>
            <li>Rebalancing And Scaling</li>
            <li>Backup/Restore And Monitoring</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Prospek Technologies can help you achieve all your digital transformation goals.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>MONGODB SERVICES</h5>
          <hr className="line-break" />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            As a MongoDB partner, we provide guidance for all project phases, including system evaluation, architecture, design, resource planning, implementation, performance, and legacy data migration strategies.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Our Services</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            We can help you identify the key factors influencing the database design and provide a step-by-step roadmap for the project.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Licenses</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            We can manage implementation, product maintenance, renewals, and upgrades, as well as handle any MongoDB product-related requests.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Strategic Consulting</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            We provide solutions on Big Data adoption with considerations for hardware and software, storage aspects, database parameters, and database security settings. We also cover important aspects such as high availability, horizontal scaling, performance monitoring tools, data durability, and various backup strategies.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Implementation & Maintenance, 3rd party Integration, Legacy Data Migration</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Our engineers will work with your team to build MongoDB-based solutions with lower development costs, straightforward deployment, and an easy monitoring system that seamlessly integrates with your existing enterprise application.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Use Case: Hardening of MongoDB systems for implementation into production</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            We provide your SA's and DBA's with detailed references for pre- and post-MongoDB deployment.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Use Case: Design Decisions</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>Key Factors</li>
            <li>Database Design</li>
            <li>Data Modeling</li>
            <li>Schema Validation</li>
            <li>Security And Privacy</li>
            <li>Use TLS/SSL To Encrypt Communication Channels Between Application And Database.</li>
            <li>Access Control To Specify The Authentication Mechanism SCRAM-SHA-1, SCRAM-SHA-256, LDAP, Kerberos Or An Existing External Framework) To Require All Clients And Servers To Provide Valid Credentials Before They Can Connect To The System.</li>
            <li>Auditing For Mongod And Mongos Instances To Track System Activity For Deployments With Multiple Users And Applications.</li>
            <li>Native Encryption Option To Encrypt Data Files Such That Only Parties With The Decryption Key Can Decode And Read The Data.</li>
            <li>Performance And Maintenance</li>
            <li>Deployment With Any Topology, At Any Scale.</li>
            <li>Deployment Of New Clusters And Managing/Monitoring And Backing Up Existing Ones.</li>
            <li>Quick Upgrades With No Downtime.</li>
            <li>Adding Capacity, Without Taking Application Offline.</li>
            <li>Point-In-Time And Scheduled Backup: Restore To Any Point In Time.</li>
            <li>Monitoring Of System Metrics And Setup Custom Alerts</li>
            <li>Query Optimization To Identify Slow-Running Queries, New Index Suggestions With Automate Index Builds.</li>
            <li>Data Consistency</li>
            <li>Journaling Will Be Enabled Across All The Mongo Database Instances To Provide Durability In The Event Of A Failure.</li>
            <li>Use A Higher Level Of Write Concern To Request Acknowledgement From MongoDB For Write Operations.</li>
            <li>Use The Read Concern Option To Control The Recency, Consistency, And Isolation Properties Of The Data Read From Replica Sets And Replica Set Shards.</li>
            <li>Data Migration</li>
            <li>Real-Time Continuous Big Data Integration - DBS-H Tool</li>
            <li>Database Administration And Monitoring</li>
            <li>Application Servers</li>
            <li>Database Servers</li>
            <li>System Information</li>
            <li>Database Management System Configuration</li>
            <li>Backup</li>
            <li>Backup By Copying Underlying Datafiles With File System Snapshots</li>
            <li>Backup With Mongodump (Not Recommended For Large Scale Deployments)</li>
            <li>MongoDB Ops Manager/Cloud Manage</li>
            <li>Point-In-Time, Continuous And Scheduled Backup: Restore To Any Point In Time.</li>
            <li>Queryable Backups To Perform Targeted Restores.</li>
            <li>Backup/Restore With Datos IO (Link)</li>
            <li>Integrating With BI Tools Such As QlikView, Tableau, Informatica</li>
          </ul>
        </Col>
      </Row>

    </Container>
  );
};

export default BigdataSolutions;

import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import ServiceCardGroup from '../util/ServiceCardGroup';
import { services } from '../constants';

import "./ServicesPage.css";

const ServicesPage = () => {
  return (
    <Container className="services-container">
      <Row>
        <Col>
          <h5>Our Services</h5>
          <h3>What We Do?</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <ServiceCardGroup serviceGroups={services}/>
        </Col>
      </Row>
    </Container>
  );
}

export default ServicesPage;